import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button } from '@whitelabel/component-library';
import { ReactComponent as Cross } from '@whitelabel/media/icons/small/cross.svg';
import commonMessages from '@whitelabel/helpers/messages/commonMsg';
import { ZENDESK_API_URL } from '@whitelabel/xcover-shared/helpers/api';
import { RootState } from '../../store';
import { StyledModal, StyledNPS } from './styledNPSfeedbackModal';

interface INPSfeedbackModalProps {
  score?: string;
  source: string;
  bookingID: string;
}
const NPSfeedbackModal = ({ score, source, bookingID }: INPSfeedbackModalProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState(true);
  const [isFeedbackGiven, setIsFeedbackGiven] = useState(false);
  const toggle = () => {
    setIsOpen((pre) => !pre);
  };
  const onFeedbackGiven = () => {
    setIsFeedbackGiven(true);
  };

  const customer = useSelector((state: RootState) => state.customer.data);

  const body = (
    <StyledNPS
      headingTag="h1"
      source={source}
      bookingID={bookingID}
      score={score ? Number(score) : undefined}
      onFeedbackGiven={onFeedbackGiven}
      zendeskApiURL={ZENDESK_API_URL as string}
      isModal
      customerName={customer && `${customer?.firstName} ${customer?.lastName}`}
      customerEmail={customer?.email}
      customerPhone={customer?.phone}
    />
  );

  return (
    <StyledModal
      isOpen={isOpen}
      centered
      body={body}
      toggle={toggle}
      footer={
        isFeedbackGiven ? (
          <Button
            color="primary"
            icon={Cross}
            buttonType="secondary"
            onClick={toggle}
            data-analytics="cancelModifyPolicy"
          >
            {formatMessage(commonMessages.closeButtonText)}
          </Button>
        ) : (
          ''
        )
      }
    />
  );
};

export default NPSfeedbackModal;
