import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { COOKIE_YES_MODAL_ACTIONED } from '@whitelabel/xcover-shared/helpers/constants';
import { RootState } from '../store';
import { hasCookieYesActioned } from './utils';

const useShowCookieModal = () => {
  const [isCookieModalOpen, setIsCookieModalOpen] = useState(
    // cookie modal does not work on localhost
    process.env.NODE_ENV !== 'development' ? !hasCookieYesActioned() : false,
  );
  const cookieModalActioned = useSelector(({ customer }: RootState) => customer)[COOKIE_YES_MODAL_ACTIONED];
  useEffect(() => {
    if (cookieModalActioned) {
      setIsCookieModalOpen(false);
    }
  }, [cookieModalActioned]);

  return isCookieModalOpen;
};

export default useShowCookieModal;
